import React from 'react';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import { PrimaryButton } from '@shipae/components-sandbox/component/buttons';

import { useTranslation } from 'src/views/misc/localization';
import SmartLink from 'components/smart-link';
import {
  Countries,
  // SelectThemeEx,
} from './styles';
import {
  Title,
  PanelTheme,
  // FormElementTheme,
} from '../styles';
/*
import {
  setHeroAirOceanFormData,
} from 'src/application/lazy/hero/actions';
import FormElement from '@shipae/components-sandbox/component/form-element';
import { countries } from 'application/constants/countries';
import Select, {
  ValueType,
} from '@shipae/components-sandbox/component/select';
*/

type AirOceanFreightProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const AirOceanFreight: React.FC<AirOceanFreightProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    heroAirOceanFormData: formData,
  } = useSelector(getHero);
  const {
    // origin,
    // destination,
    quoteUrl,
  } = formData;

  /*
  const onOriginChange = (option?: ValueType) => {
    dispatch(setHeroAirOceanFormData({
      ...formData,
      origin: option,
    }));
  };

  const onDestinationChange = (option?: ValueType) => {
    dispatch(setHeroAirOceanFormData({
      ...formData,
      destination: option,
    }));
  };
  */

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{title}</Title>
      <Countries>
        {/*
        <FormElement
          label={t('originCountry')}
          hasValue={!!origin}
          theme={FormElementTheme}
          required
        >
          <Select
            value={origin}
            options={countries}
            onChange={onOriginChange}
            theme={SelectThemeEx}
          />
        </FormElement>
        <FormElement
          label={t('destinationCountry')}
          hasValue={!!destination}
          theme={FormElementTheme}
          required
        >
          <Select
            value={destination}
            options={countries}
            onChange={onDestinationChange}
            theme={SelectThemeEx}
          />
        </FormElement>
        */}
      </Countries>
      <PrimaryButton
        href={quoteUrl}
        linkComponent={SmartLink}
      >
        {t('getQuote')}
      </PrimaryButton>
    </PanelContainer>
  );
};

export default AirOceanFreight;
